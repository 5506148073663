<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <i class="fi-upload-cloud"></i>&nbsp;&nbsp;&nbsp;Daten-Export
          </h3>
        </div>
      </div>

      <div v-if="organization && organization.facilities && organization.facilities.length <= 0"
           class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Daten-Exporte"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell">
          <md-card>
            <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <md-tabs id="dataExportTabsId" md-dynamic-height>
                  <md-tab md-label="KiBiG-Web">
                    <div class="grid-x grid-padding-x">
                      <div v-if="availableFacilities && availableFacilities.length > 0"
                           class="cell large-3 medium-6 small-12">
                        <md-field>
                          <label>Einrichtung</label>
                          <md-select v-model="selectedFacilityReferenceNumber"
                                     :disabled="sending || allowedFacilities.length <= 1"
                                     @md-selected="onSelectFacility">
                            <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                                       :key="facility.referenceNumber">
                              {{ facility.longName }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="cell large-1 medium-1 small-0">
                        <div v-if="sending">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                      </div>
                      <div class="cell large-2 medium-5 small-3">
                        <md-field>
                          <label>Jahr</label>
                          <md-select v-model="selectedYear" :disabled="sending" @md-selected="onSelectExportYear">
                            <md-option v-for="year of exportYears" :value="year" :key="year">
                              {{ year }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="large-6 medium-0 small-0"></div>
                    </div>
                    <div class="grid-x grid-padding-x">
                      <div class="cell medium-12 large-6">
                        <div v-if="selectedFacilityReferenceNumber" class="grid-x grid-padding-x">
                          <div class="cell">
                            <p style="font-size: 1rem">KiBiG Einrichtungsnummer:
                              <router-link :to="editKibigDataInFacilitySettingsLink">

                                  <span v-if="selectedFacility && selectedFacility.kibigData && selectedFacility.kibigData.kibigFacilityNumber"
                                        style="color: cornflowerblue">
                                    {{ selectedFacility.kibigData.kibigFacilityNumber }}
                                    <md-tooltip>KiBiG Einrichtungsnummer jetzt in der Einrichtungsverwaltung bearbeiten.</md-tooltip>
                                  </span>

                                <span v-else style="color: orangered">nicht angegeben
                                    <md-tooltip>Fehlende KiBiG Einrichtungsnummer jetzt in der Einrichtungsverwaltung eintragen.</md-tooltip>
                                  </span>
                              </router-link>
                            </p>
                          </div>
                          <div class="cell">
                            <p style="font-size: 1rem">KiBiG XML-Import-Key:
                              <router-link :to="editKibigDataInFacilitySettingsLink">
                                  <span v-if="selectedFacility && selectedFacility.kibigData.kibigXmlImportKey"
                                        style="color: cornflowerblue">
                                    {{ selectedFacility.kibigData.kibigXmlImportKey }}
                                    <md-tooltip>KiBiG XML-Import-Key jetzt in der Einrichtungsverwaltung bearbeiten.</md-tooltip>
                                  </span>
                                <span v-else style="color: orangered">nicht angegeben
                                    <md-tooltip>
                                      Fehlenden KiBiG XML-Import-Key jetzt in der Einrichtungsverwaltung eintragen.
                                    </md-tooltip>
                                  </span>
                              </router-link>
                            </p>
                          </div>
                        </div>
                        <div
                            v-if="selectedFacilityReferenceNumber && selectedFacility && selectedFacility.kibigData && selectedFacility.kibigData.kibigFacilityNumber && selectedFacility.kibigData.kibigXmlImportKey"
                            class="grid-x grid-padding-x">
                          <div class="cell">
                            <md-field>
                              <label>Daten
                                <md-tooltip>Daten auswählen, die an das KiBiG-Web übertragen oder als XML-Datei
                                  exportiert werden sollen.
                                </md-tooltip>
                              </label>
                              <md-select v-model="kibigUploadConfig.importModes" :disabled="sending" multiple>
                                <md-option v-for="mode of labels.kibigImportModes" :value="mode" :key="mode">
                                  {{ labels.kibigImportMode[mode] }}
                                </md-option>
                              </md-select>
                            </md-field>
                          </div>
                          <div v-if="!kibigCredentialsStored" class="cell small-6">
                            <md-field :class="getValidationClass('username')">
                              <label for="kibig-username">KiBiG-Web Benutzername</label>
                              <md-input name="kibig-username" id="kibig-username"
                                        v-model="kibigUploadConfig.credentials.username"
                                        :disabled="sending"/>
                              <span class="md-error"
                                    v-if="!$v.credentials.username.required">Benutzername ist notwendig</span>
                            </md-field>
                          </div>
                          <div v-if="!kibigCredentialsStored" class="cell small-6">
                            <md-field :class="getValidationClass('password')">
                              <label for="kibig-password">KiBiG-Web Passwort</label>
                              <md-input name="kibig-password" id="kibig-password"
                                        v-model="kibigUploadConfig.credentials.password"
                                        :disabled="sending" type="password"/>
                              <span class="md-error"
                                    v-if="!$v.credentials.password.required">Passwort ist notwendig</span>
                            </md-field>
                          </div>
                          <div v-if="!kibigCredentialsStored" class="cell">
                            <md-checkbox class="md-primary" v-model="kibigUploadConfig.credentials.storeForFurtherUse">
                              KiBiG-Web Anmeldedaten für zukünftige Nutzung speichern
                              <md-tooltip>Die KiBiG-Web Anmeldedaten können für die zukünftige Nutzung im SoSE-System
                                (verschlüsselt) gespeichert werden.<br>
                                <b>Wichtig</b>: Ihre Anmeldedaten werden <b>nicht in Ihrem Web-Browser gespeichert</b>!
                              </md-tooltip>
                            </md-checkbox>
                          </div>
                          <div v-if="kibigCredentialsStored" class="cell">
                            <button class="button secondary" @click="kibigCredentialsStored = false">Zugangsdaten neu
                              eingeben
                              <md-tooltip>Ihre Zugangsdaten sind bereits im System hinterlegt.
                                Sie können ohne Neueingabe einfach fortfahren.<br><br>Wenn Sie ihre Zugangsdaten
                                aktualisieren müssen, klicken Sie hier.
                              </md-tooltip>
                            </button>
                          </div>
                          <div class="cell">
                            <br>
                            <md-card>
                              <md-card-header>
                                <h3 class="md-title" style="color: cornflowerblue">Haftungsausschluss</h3>
                              </md-card-header>
                              <md-card-content style="text-align: justify">
                                Wir, die ai42 UG (haftungsbeschränkt), entwickeln die SoSE-Software mit größter Sorgfalt
                                und testen allen Funktionen äußert gründlich.
                                Trotz dieser hohen Qualitätsanforderung kann nicht ausgeschlossen werden, dass alle
                                Funktionen absolut fehlerfrei arbeiten.
                                Speziell bei der Datenübertragung zum KiBiG-Web sind wir abhängig von einer stabilen
                                technischen Schnittstelle, welche wir nur benutzen und nicht beeinflussen können.
                                Daher können wir in diesem Fall nicht garantieren, dass die Kommunikation zwischen SoSE
                                und dem KiBiG-Web stets fehlerfrei abläuft.
                                <br><br>
                                <b>Sollten in dem unwahrscheinlichen Fall bei der Übertragung zum KiBiG-Web Fehler
                                  auftreten, so übernimmt die ai42 UG (haftungsbeschänkt) absolut
                                  <span style="color: cornflowerblue">keine Haftung für entstandene Schäden</span>.</b>
                                <br><br>
                                Sie müssen diesem Haftungsausschluss zustimmen, wenn Sie die KiBiG-Web
                                Daten-Export-Funktion nutzen möchten.
                              </md-card-content>
                            </md-card>
                            <br>
                            <md-checkbox class="md-primary" v-model="disclaimerAccepted" style="text-align: justify">Ich
                              habe den Haftungsausschluss verstanden und stimme diesem bei der Verwendung der KiBiG-Web
                              Daten-Export-Funktion zu.
                            </md-checkbox>
                          </div>
                          <div class="cell large-6">
                            <br><br>
                            <button @click="onTransferToKibigWeb" class="button success expanded"
                                    :disabled="dataUploadNotPossible">
                              <i class="fi-upload-cloud"></i>&nbsp;&nbsp;&nbsp;An KiBiG-Web senden
                              <md-tooltip>Ausgewählte Daten an das KiBiG-Web übertragen.</md-tooltip>
                            </button>
                            <md-tooltip><span
                                v-if="!disclaimerAccepted">Bitte stimmen Sie dem Haftungsausschluss zu.</span>
                            </md-tooltip>
                          </div>
                          <div class="cell large-6">
                            <br><br>
                            <button @click="onExportKibigWebXML" class="button expanded"
                                    :disabled="xmlDownloadNotPossible">
                              <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;KiBiG-Web XML-Datei
                              <md-tooltip>KiBiG-Web XML-Datei herunterladen</md-tooltip>
                            </button>
                            <md-tooltip><span
                                v-if="!disclaimerAccepted">Bitte stimmen Sie dem Haftungsausschluss zu.</span>
                            </md-tooltip>
                          </div>
                        </div>
                      </div>
                      <div class="cell medium-0 large-6">
                      </div>
                    </div>
                  </md-tab>
                  <md-tab v-if="isAdmin || isFacilityAdmin || isManagement" md-label="Landesamt für Statistik">
                    <div v-if="statisticsDisabled" class="grid-x">
                      <div class="cell large-12 medium-12 small-12">
                        <h5 style="color: cornflowerblue"><b>Aktueller Hinweis</b></h5>
                        <p style="font-size: large">
                          Die Funktion für den Export der Daten für das Landesamt für Statistik wird derzeit überarbeitet
                          und steht in Kürze wieder zur Verfügung. Danke für Ihr Verständnis.
                        </p>
                      </div>
                    </div>
                    <div v-else class="grid-x">
                      <div class="cell large-12 medium-12 small-12">
                        <h5 style="color: cornflowerblue"><b>Wichtiger Hinweis</b></h5>
                        <p style="font-size: large">Bitte lesen Sie zunächst die <a
                            href="https://ai42.de/wp-content/uploads/2021/03/SoSE-Anleitung-LfS-Export.pdf"
                            target="_blank">Anleitung zum Export der Daten für das Landesamt für Statistik</a></p>
                      </div>

                      <div class="cell large-6 medium-6 small-12">
                        <div class="grid-x grid-padding-x">
                          <div v-if="availableFacilities && availableFacilities.length > 0" class="cell">
                            <md-field>
                              <label>Einrichtungen</label>
                              <md-select v-model="selectedFacilityReferenceNumbers" multiple :disabled="sending"
                                         @md-selected="onSelectFacility">
                                <md-option v-for="facility of allowedFacilities" :value="facility.referenceNumber"
                                           :key="facility.referenceNumber">
                                  {{ facility.longName }}
                                </md-option>
                              </md-select>
                            </md-field>
                          </div>
                          <div class="cell large-4 medium-6 small-12">
                            <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                           v-model="effectiveDate" md-immediately :disabled="sending"
                                           :class="getValidationClass('statisticEffectiveDate')"
                                           @md-closed="onCloseEffectiveDatePicker">
                              <label>Stichtag</label>
                              <span class="md-error" v-if="$v.statisticEffectiveDate.$model === 'Invalid date'">Stichtag ist notwendig</span>
                            </md-datepicker>
                          </div>
                          <div class="cell large-4 medium-6 small-6">
                            <md-checkbox class="md-primary" style="margin-top: 28px;" v-model="useGroups">Mit
                              Gruppenstruktur
                              <md-tooltip>Mitarbeiter und Kinder mit Gruppenstruktur melden.
                              </md-tooltip>
                            </md-checkbox>
                          </div>
                          <div class="cell large-4 medium-6 small-6">
                            <md-checkbox v-if="useGroups" style="margin-top: 28px;" class="md-primary"
                                         v-model="autoGroup">automatisch
                              <md-tooltip>Die Gruppen werden aus den Bereichen<br>(Krippe, Kindergarten, Hort) bestimmt.
                              </md-tooltip>
                            </md-checkbox>
                          </div>
                          <div class="cell large-6 medium-6 small-10">
                            <br>
                            <button @click="onStateOfficePreview" class="button expanded"
                                    :disabled="selectedFacilityReferenceNumbers.length <= 0 || effectiveDate === null  || effectiveDate === '' || sending">
                              <i class="fi-eye"></i>&nbsp;&nbsp;&nbsp;Landesamt für Statistik Vorschau
                              <md-tooltip md-direction="top">Vorschau Landesamt für Statistik erzeugen und
                                überprüfen.<br>
                                Im Anschluss kann die CSV-Datei heruntergeladen werden.
                              </md-tooltip>
                            </button>
                          </div>
                          <div class="cell large-6 medium-6 small-2">
                            <br>
                            <div v-if="sending">
                              <vue-simple-spinner></vue-simple-spinner>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="stateOfficeStatisticData" class="cell">
                        <div class="grid-x grid-padding-x">

                          <div class="cell">
                            <br>
                            <h5><span
                                style="color: cornflowerblue">{{ stateOfficeStatisticData.lfStatDataChild.length }} Kinder</span>
                            </h5>
                            <table class="hover">
                              <thead>
                              <tr>
                                <th style="color: gray; text-align: left;">{{ labels.lfsHeader['fullname'] }}
                                  <md-tooltip>Dieses Feld wird nicht exportiert.</md-tooltip>
                                </th>
                                <th style="color: gray; text-align: left;">{{ labels.lfsHeader['kibigId'] }}
                                  <md-tooltip>Dieses Feld wird nicht exportiert.</md-tooltip>
                                </th>
                                <th>&nbsp;</th>
                                <th v-for="field of stateOfficeStatisticData.exportedFieldsOfChild"
                                    :key="'child' + field">{{ labels.lfsHeader[field] }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(child, index) of stateOfficeStatisticData.lfStatDataChild"
                                  :key="'child-' + child['kibigId'] + index">
                                <td style="color: gray; text-align: left;"><i
                                    style="color: gray;">{{ child['fullname'] }}</i></td>
                                <td style="color: gray; text-align: left;"><i
                                    style="color: gray;">{{ child['kibigId'] }}</i></td>
                                <td>&nbsp;</td>
                                <td v-for="(field, index) of stateOfficeStatisticData.exportedFieldsOfChild"
                                    :key="'child' + index + child['kibigId'] + child[field]">
                                    <span v-if="labels.lfsHeader[field + '_' + child[field]]">
                                      {{ labels.lfsHeader[field + '_' + child[field]] }}
                                    </span>
                                  <span v-else>
                                      {{ child[field] }}
                                    </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="cell">
                            <br>
                            <h5><span
                                style="color: cornflowerblue">{{ stateOfficeStatisticData.lfStatDataEmployee.length }} Mitarbeiter</span>
                            </h5>
                            <table class="hover">
                              <thead>
                              <tr>
                                <th style="color: gray; text-align: left;">{{ labels.lfsHeader['fullname'] }}
                                  <md-tooltip>Dieses Feld wird nicht exportiert.</md-tooltip>
                                </th>
                                <th style="color: gray; text-align: left;">{{ labels.lfsHeader['kibigId'] }}
                                  <md-tooltip>Dieses Feld wird nicht exportiert.</md-tooltip>
                                </th>
                                <th>&nbsp;</th>
                                <th v-for="field of stateOfficeStatisticData.exportedFieldsOfEmployee"
                                    :key="'employee' + field">{{ labels.lfsHeader[field] }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="employee of stateOfficeStatisticData.lfStatDataEmployee"
                                  :key="'employee-' + employee['employeeNo']">
                                <td style="color: gray; text-align: left;"><i
                                    style="color: gray;">{{ employee['fullname'] }}</i></td>
                                <td style="color: gray; text-align: left;"><i
                                    style="color: gray;">{{ employee['kibigId'] }}</i></td>
                                <td>&nbsp;</td>
                                <td v-for="(field, index) of stateOfficeStatisticData.exportedFieldsOfEmployee"
                                    :key="'employee' + index + employee['kibigId'] + employee[field]">
                                    <span v-if="labels.lfsHeader[field + '_' + employee[field]]">
                                      {{ labels.lfsHeader[field + '_' + employee[field]] }}
                                    </span>
                                  <span v-else-if="employee[field] !== -1">
                                      {{ employee[field] }}
                                    </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                          <div v-if="stateOfficeStatisticData.errors.length > 0" class="cell">
                            <br>
                            <h5 class="warn"><b>ACHTUNG</b>: Ihre Daten enthalten folgende Fehler und sollten so nicht
                              gemeldet werden.</h5>
                            <md-content>
                              <div v-for="error of stateOfficeStatisticData.errors" :key="error" class="warn">
                                {{ error }}
                              </div>
                            </md-content>
                          </div>

                          <div class="cell">
                            <md-checkbox style="margin-top: 32px;" class="md-primary" v-model="confirmChildData">Die
                              Daten für die Kinder sind korrekt.
                              <md-tooltip>Die Korrektheit der Daten für die Kinder<br>muss von dem CSV-Export bestätigt
                                werden.
                              </md-tooltip>
                            </md-checkbox>
                          </div>
                          <div class="cell">
                            <md-checkbox style="margin-top: -10px;" class="md-primary" v-model="confirmEmployeeData">Die
                              Daten für die Mitarbeiter sind korrekt.
                              <md-tooltip>Die Korrektheit der Daten für die Mitarbeiter<br>muss von dem CSV-Export
                                bestätigt werden.
                              </md-tooltip>
                            </md-checkbox>
                          </div>
                          <div class="cell large-3 medium-3 small-12">
                            <br>
                            <button @click="onStateOfficeExport" class="button success expanded"
                                    :disabled="selectedFacilityReferenceNumbers.length <= 0 || effectiveDate === null
                                      || effectiveDate === '' || sending || !confirmChildData || !confirmEmployeeData">
                              <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Landesamt für Statistik CSV-Datei
                            </button>
                            <md-tooltip>
                              <span v-if="!confirmChildData || !confirmEmployeeData">Die Korrektheit der Daten für Kinder und Mitarbeiter<br>müssen vor dem CSV-Export bestätigt werden.</span>
                              <span v-else>Landesamt für Statistik CSV-Datei erzeugen und herunterladen</span>
                            </md-tooltip>
                          </div>

                        </div>
                      </div>
                    </div>
                  </md-tab>
                </md-tabs>
              </div>
            </div>
          </md-card>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDataExportDialog">
        <div v-if="selectedFacilityReferenceNumber" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Download als KiBiG-Web XML-Datei
          </md-dialog-title>
          <div class="cell">
            <md-content v-if="!sending">Sollen die Daten
              <b class="dialog-title">({{ selectedImportModesLabel }})</b> für die Einrichtung
              <b class="dialog-title">{{ selectedFacilityName }}</b> und das Jahr
              <b class="dialog-title">{{ selectedYear }}</b> als KiBiG-Web XML-Datei heruntergeladen werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="exportKibigWebXML" class="button success" :disabled="sending">
              <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja, XML-Datei herunterladen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showDataExportDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
            </button>
          </div>
          <button class="close-button" type="button" @click="showDataExportDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDataTransferDialog">
        <div v-if="selectedFacilityReferenceNumber && selectedFacility" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-upload-cloud"></i>&nbsp;&nbsp;&nbsp;Daten an das KiBiG-Web übertragen
          </md-dialog-title>
          <div class="cell">
            <md-content v-if="!this.kibigUploadResult">Sollen die Daten
              <b class="dialog-title">({{ selectedImportModesLabel }})</b> für die Einrichtung
              <b class="dialog-title">{{ selectedFacilityName }}</b>
              (KiBiG Einrichtungsnummer: {{ selectedFacility.kibigData.kibigFacilityNumber }}) und das Jahr
              <b class="dialog-title">{{ selectedYear }}</b> an das KiBiG-Web übertragen werden?
            </md-content>
            <md-content v-if="this.kibigUploadResult && !this.kibigUploadResult.success">
              <b style="color: red; font-size: 1.2rem">KiBiG-Web Fehlermeldung:</b><br><br>
              Einrichtung: <span style="color: cornflowerblue">{{ selectedFacilityName }}</span><br>
              KiBiG Nummer: <span
                style="color: cornflowerblue">{{ selectedFacility.kibigData.kibigFacilityNumber }}</span><br>
              Jahr: <span style="color: cornflowerblue">{{ selectedYear }}</span><br>
              Daten: <span style="color: cornflowerblue">{{ selectedImportModesLabel }}</span><br><br>
              Fehlerbeschreibung:<br>
              <span
                  style="color: cornflowerblue">{{ this.labels.errorMessage[this.kibigUploadResult.field] }}</span><br><br>
              Hinweis:<br>
              Bitte überprüfen Sie für die gewählte Einrichtung <span
                style="color: cornflowerblue">{{ selectedFacilityName }}</span>
              die für den Upload notwendigen Angaben für
              <span style="color: cornflowerblue">KiBiG Einrichtungsnummer</span>,
              <span style="color: cornflowerblue">KiBiG XML-Import-Key</span>,
              <span style="color: cornflowerblue">Benutzername</span> und
              <span style="color: cornflowerblue">Passwort</span>.
            </md-content>
            <md-content v-if="this.kibigUploadResult && this.kibigUploadResult.success">
              <b style="color: seagreen; font-size: 1.2rem">Übertragung an das KiBiG-Web erfolgreich.</b><br><br>
              Die Daten <span style="color: cornflowerblue">{{ selectedImportModesLabel }}</span> für die
              Einrichtung <span style="color: cornflowerblue">{{ selectedFacilityName }}</span> mit der
              KiBiG Nummer <span
                style="color: cornflowerblue">{{ selectedFacility.kibigData.kibigFacilityNumber }}</span> und
              Jahr <span style="color: cornflowerblue">{{ selectedYear }}</span>
              wurden erfolgreich an das KiBiG-Web übertragen.<br>
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button v-if="!this.kibigUploadResult" @click="uploadKibigData" class="button success" :disabled="sending">
              <i class="fi-upload-cloud"></i>&nbsp;&nbsp;&nbsp;Ja, Daten übertragen
            </button>
            <button v-if="!this.kibigUploadResult" class="button alert" style="margin-left: 1rem;"
                    @click="showDataTransferDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
            </button>
            <button v-else class="button" style="margin-left: 1rem;" @click="showDataTransferDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Schliessen
            </button>
          </div>
          <button class="close-button" type="button" @click="showDataTransferDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Daten-Export"></NoModuleCard>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import FacilityService from '../services/FacilityService';
import HttpErrorHandler from '../services/HttpErrorHandler';
import NoFacilityCard from "../components/cards/NoFacilityCard";

import Vue from 'vue';
import BugReport from "../components/fabs/BugReport";
import {minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import OrganizationService from "../services/OrganizationService";
import BetaFunctionCard from "../components/cards/BetaFunctionCard";
import NoModuleCard from '../components/cards/NoModuleCard';
import jQuery from "jquery";

export default {
  name: 'DataExport',
  mixins: [validationMixin],
  components: {
    BetaFunctionCard,
    NoModuleCard,
    BugReport,
    'vue-simple-spinner': Spinner,
    NoFacilityCard,
  },

  created() {
    this.initDatePickerSyncHandler(true);
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadKibigData();
    this.formatEffectiveDate();

    HttpErrorHandler.maintainDarkMode(this);
  },

  beforeDestroy() {
    this.cleanXMLs();
    this.xmlUrl = null;
    this.cleanCSVs();
    this.csvUrl = null;
    this.initDatePickerSyncHandler(false);
  },

  data() {
    return {
      sending: false,
      calculatedKibigData: null,
      selected: null,
      success: false,
      allowedFacilities: [],
      availableFacilities: [],
      availableFacilitiesMap: {},
      selectedFacilityReferenceNumber: null,
      selectedFacilityReferenceNumbers: [],
      selectedFacilityName: '',
      selectedFacility: null,
      selectedYear: Vue.moment().format("YYYY"),
      exportYears: [],

      effectiveDate: Vue.moment(Vue.moment().format('YYYY') + '-03-01').format('DD.MM.YYYY'),
      statisticEffectiveDate: '',
      useGroups: false,
      autoGroup: false,

      confirmChildData: false,
      confirmEmployeeData: false,

      showDataExportDialog: false,
      showDataTransferDialog: false,

      xmlUrl: null,
      xmlLink: null,
      xmlBlob: null,

      csvUrl: null,
      csvLink: null,
      csvBlob: null,

      kibigUploadConfig: {
        credentials: {
          username: '',
          password: '',
          storeForFurtherUse: false,
        },
        importModes: ['CHILDS'],
      },

      kibigUploadResult: null,
      kibigCredentialsStored: false,

      editKibigDataInFacilitySettingsLink: '/Verwaltung/Einrichtungen/',

      disclaimerAccepted: false,
      stateOfficeStatisticData: null,
    }
  },

  validations: {
    credentials: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(4)
      }
    },
    statisticEffectiveDate: {
      required,
    },
  },

  methods: {

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
    },

    initData() {
      if (this.exportYears.length <= 0) {
        for (let i = 0; i < 2; i++) {
          this.exportYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i), 'DD-MM-YYYY').format('YYYY'));
        }
      }
      this.kibigUploadConfig.importModes = this.labels.kibigImportModes;
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
            this.effectiveDate = jQuery('#valid-from-date-dp').children('input').val();
            this.writeBackDates();
            this.onCloseEffectiveDatePicker();
          });
        }, 500);
      } else {
        jQuery('#valid-from-date-dp').children('input').unbind('blur');
        jQuery('.md-button.md-icon-button.md-dense.md-input-action.md-clear').unbind('click');
      }
    },

    formatEffectiveDate() {
      if (this.effectiveDate === null) {
        if (this.fee && this.fee.validFrom) {
          this.effectiveDate = Vue.moment(this.fee.validFrom).format('DD.MM.YYYY');
        }
      }
    },

    onCloseEffectiveDatePicker() {
      setTimeout(() => this.effectiveDate = jQuery('#valid-from-date-dp').children('input').val(), 100);

      setTimeout(() => {
        jQuery('.md-button.md-icon-button.md-dense.md-input-action.md-clear').bind('click', () => {
          this.effectiveDate = jQuery('#valid-from-date-dp').children('input').val();
          this.writeBackDates();
        });
      }, 250);
    },

    writeBackDates() {
      if (this.effectiveDate) {
        this.statisticEffectiveDate = Vue.moment(this.effectiveDate, 'DD.MM.YYYY').format('YYYYMMDD');
        if (this.statisticEffectiveDate === 'Invalid date') {
          this.statisticEffectiveDate = null;
          this.effectiveDate = '';
        }
      } else {
        this.statisticEffectiveDate = null;
      }
    },

    getAllOrganizationFacilities() {
      this.availableFacilities = this.getAvailableFacilities;
      this.buildAvailableFacilities();
    },

    getValidationClass(fieldName) {
      const field = this.$v.credentials[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    onSelectExportYear() {
      this.onSelectFacility();
    },

    onSelectFacility() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        // localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = facility.name;
            this.selectedFacility = facility;
          }
        }

        this.editKibigDataInFacilitySettingsLink = '/Verwaltung/Einrichtungen/' + this.selectedFacilityReferenceNumber;
        if (this.selectedFacility) {
          this.kibigCredentialsStored = this.selectedFacility.kibigData.kibigCredentialsStored;
        }
      }
    },

    buildAvailableFacilities() {
      this.allowedFacilities = []
      for (let facility of this.availableFacilities) {
        if (facility.referenceNumber !== '*') {
          this.allowedFacilities.push(facility);
        }
      }

      if (this.selectedFacilityReferenceNumber === '*') {
        if (this.allowedFacilities.length > 1) {
          this.selectedFacilityReferenceNumber = '';
        }
        if (this.allowedFacilities.length === 1) {
          this.selectedFacilityReferenceNumber = this.allowedFacilities[0].referenceNumber;
        }
      }

      this.onSelectFacility();
    },

    reloadKibigData() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {

          if (!this.isAdmin) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
          }

          this.initData();
          this.getAllOrganizationFacilities();

          this.sending = false;
          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    onExportKibigWebXML() {
      this.showDataExportDialog = true;
    },

    onTransferToKibigWeb() {
      this.kibigUploadResult = null;
      this.showDataTransferDialog = true;
    },

    onStateOfficePreview() {
      this.confirmChildData = false;
      this.confirmEmployeeData = false;
      this.writeBackDates();
      this.getStateOfficePreview(this.statisticEffectiveDate, this.selectedFacilityReferenceNumbers, this.useGroups, this.autoGroup);
    },

    getStateOfficePreview(date, facilityReferenceNumbers, useGroups, autoGroup) {
      this.sending = true;
      this.stateOfficeStatisticData = null;
      OrganizationService.getStateOfficeStatisticPreview(date, facilityReferenceNumbers, useGroups, autoGroup)
          .then(response => {
            this.sending = false;
            this.stateOfficeStatisticData = response.data;
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erstellen der Vorschau von Landesamt für Statistik');
          })
    },

    onStateOfficeExport() {
      this.writeBackDates();
      this.exportStateOfficeCSV(this.statisticEffectiveDate, this.selectedFacilityReferenceNumbers, this.useGroups, this.autoGroup);
    },

    exportStateOfficeCSV(date, facilityReferenceNumbers, useGroups, autoGroup) {
      this.sending = true;
      OrganizationService.getStateOfficeStatisticCSV(date, facilityReferenceNumbers, useGroups, autoGroup)
          .then(response => {
            setTimeout(() => {
              this.cleanCSVs();

              this.csvBlob = new Blob([response.data]);
              this.csvUrl = window.URL.createObjectURL(this.csvBlob);
              this.csvLink = document.createElement('a');
              const id = this.organization.name + ' (' + facilityReferenceNumbers.toString() + ') Landesamt für Statistik Daten ' + Vue.moment(this.effectiveDate, 'DD.MM.YYYY').format('DD-MM-YYYY');
              this.csvLink.href = this.csvUrl;
              this.csvLink.setAttribute('download', id + '.csv');
              this.csvLink.setAttribute('id', id);
              document.body.appendChild(this.csvLink);
              this.csvLink.click();

              this.sending = false;
              this.$store.commit('successMsg', 'Landesamt für Statistik CSV-Datei für Organisation <b>' + this.organization.name +
                  '</b> und Datum <b>' + Vue.moment(this.effectiveDate, 'DD.MM.YYYY').format('DD.MM.YYYY') +
                  '</b> wurde erfolgreich erzeugt.');
            }, 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der Landesamt für Statistik CSV-Datei für Organisation <b>' + this.organization.name +
                '</b> und Datum <b>' + Vue.moment(this.effectiveDate, 'DD.MM.YYYY').format('DD-MM-YYYY') + '</b>');
            this.sending = false;
          })
    },

    exportKibigWebXML() {
      this.sending = true;
      FacilityService.getKibigXML(this.selectedFacilityReferenceNumber, this.selectedYear, this.kibigUploadConfig)
          .then(response => {
            setTimeout(() => {
              this.showDataExportDialog = false;
              this.cleanXMLs();

              this.xmlBlob = new Blob([response.data]);
              this.xmlUrl = window.URL.createObjectURL(this.xmlBlob);
              this.xmlLink = document.createElement('a');
              const id = this.selectedFacilityName + ' (' + this.selectedFacilityReferenceNumber + ') KiBiG-Web Daten ' +
                  '(' + this.selectedImportModesLabel + ')' + ' ' + this.selectedYear;
              this.xmlLink.href = this.xmlUrl;
              this.xmlLink.setAttribute('download', id + '.xml');
              this.xmlLink.setAttribute('id', id);
              document.body.appendChild(this.xmlLink);
              this.xmlLink.click();

              this.sending = false;
              this.$store.commit('successMsg', 'KiBiG-Web XML-Datei für Einrichtung <b>' + this.selectedFacilityName +
                  ' (' + this.selectedFacilityReferenceNumber + ')</b> und Jahr <b>' + this.selectedYear +
                  '</b> wurde erfolgreich erzeugt.');
            }, 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der KiBiG-Web XML-Datei für Einrichtung <b>' + this.selectedFacilityName +
                ' (' + this.selectedFacilityReferenceNumber + ')</b> und Jahr <b>' + this.selectedYear + '</b>');
            this.sending = false;
          })
          .finally(() => {
            this.getAllOrganizationFacilities();
            this.resetUserCredentials();
          })
    },

    uploadKibigData() {
      this.sending = true;
      this.kibigUploadResult = null;
      FacilityService.uploadKibigData(this.selectedFacilityReferenceNumber, this.selectedYear, this.kibigUploadConfig)
          .then(response => {
            this.sending = false;

            this.kibigUploadResult = response.data;
            if (this.kibigUploadResult.success && this.kibigUploadResult.statusCode === 'OK') {
              this.$store.commit('successMsg', 'Die Daten für Einrichtung ' + this.selectedFacilityName +
                  '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + this.selectedYear +
                  ' wurden erfolgreich an das KiBiG-Web übertragen.');
            } else {
              this.$store.commit('errorMsg', 'Fehlermeldung vom KiBiG-Web beim Übertragen Daten für Einrichtung ' + this.selectedFacilityName +
                  '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + this.selectedYear);
            }
          })
          .catch(e => {
            this.kibigUploadResult = e.response.data;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Übertragen Daten an das KiBiG-Web für Einrichtung ' + this.selectedFacilityName +
                '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + this.selectedYear);
            this.sending = false;
          })
          .finally(() => {
            this.getAllOrganizationFacilities();
            this.resetUserCredentials();
          })
    },

    resetUserCredentials() {
      this.kibigUploadConfig.credentials.username = '';
      this.kibigUploadConfig.credentials.password = '';
      this.kibigUploadConfig.credentials.storeForFurtherUse = false;
    },

    cleanXMLs() {
      if (this.xmlUrl) {
        this.xmlBlob = null;
        document.body.removeChild(this.xmlLink);
        this.xmlLink = null;
      }
    },

    cleanCSVs() {
      if (this.csvUrl) {
        this.csvBlob = null;
        document.body.removeChild(this.csvLink);
        this.csvLink = null;
      }
    },
  },

  computed: {
    dataAvailable() {
      return this.organization && this.organization.facilities && this.user &&
          this.user.allowedFacilities && this.user.allowedFacilities.length > 0 && this.labels &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    organization() {
      return this.$store.getters.organization;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    user() {
      return this.$store.getters.user;
    },

    labels() {
      return this.$store.getters.labels
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },

    statisticsDisabled() {
      return this.$store.getters.webAppConfig.soseStatisticDisabled;
    },

    dataUploadNotPossible() {
      return (!this.selectedFacilityReferenceNumber ||
          this.selectedFacilityReferenceNumber === '*' ||
          this.kibigUploadConfig.importModes.length <= 0 ||

          ((this.kibigUploadConfig.credentials.username.length <= 0 ||
              this.kibigUploadConfig.credentials.password.length <= 3) && (!this.kibigCredentialsStored)) ||

          !this.disclaimerAccepted ||

          this.sending);
    },

    xmlDownloadNotPossible() {
      return (!this.selectedFacilityReferenceNumber ||
          this.selectedFacilityReferenceNumber === '*' ||
          this.kibigUploadConfig.importModes.length <= 0 ||

          !this.disclaimerAccepted ||

          this.sending);
    },

    selectedImportModesLabel() {
      let label = '';
      this.kibigUploadConfig.importModes.forEach(mode => {
        label += this.labels.kibigImportMode[mode] + ' ';
      })
      return label.substring(0, label.length - 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 800px;
}

i {
  color: black;
}

.dialog-title {
  color: cornflowerblue;
  font-size: larger;
}

.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: pre-wrap;
}


table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

tbody th, tbody tr, tbody td {
  padding: 3px 5px;
  text-align: center;
}

thead th, thead tr, thead td {
  padding: 3px 5px;
  text-align: center;
  // font-size: xx-small;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}

.warn {
  color: red;
}
</style>
